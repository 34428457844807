<template>
  <v-container>
    <v-row align="center">
      <v-col
        sm="8"
        md="8"
        lg="6"
        xl="4"
        class="mx-auto"
      >
        <img
          :src="require('../assets/logo.png')"
          height="30px"
          alt=""
        >
        <v-card
          class="mx-auto pa-4 pa-sm-6 pa-md-16 my-4"
          elevation="6"
          style="max-width: 600px;"
        >
          <v-row>
            <v-col>
              <h2 class="mb-2">
                Reimposta la tua password
              </h2>
            </v-col>
          </v-row>
          <v-stepper
            v-model="step"
            flat
            alt-labels
          >
            <v-stepper-content step="1">
              <v-form
                ref="form"
                class="text-left"
                @submit.prevent="pswReminder"
              >
                <v-row class="mt-1">
                  <v-col>
                    <v-text-field
                      v-model="user.password"
                      :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="show1 ? 'text' : 'password'"
                      label="Password"
                      required
                      dense
                      outlined
                      :rules="[formRules.required,formRules.pswRule]"
                      @click:append="show1 = !show1"
                      @keyup.enter="pswReminder"
                      @input="checkPassword"
                      @focus="showPswRules()"
                    >
                    </v-text-field>
                    <ul v-if="pswRulesBox">
                      <li :class="{ is_valid: contains_eight_characters }">
                        Almeno 8 caratteri
                      </li>
                      <li :class="{ is_valid: contains_number }">
                        Almeno un numero
                      </li>
                      <li :class="{ is_valid: contains_uppercase }">
                        Almeno una maiuscola
                      </li>
                      <li :class="{ is_valid: contains_special_character }">
                        Almeno un carattere speciale
                      </li>
                    </ul>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.password2"
                      :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="show2 ? 'text' : 'password'"
                      label="Conferma Password"
                      required
                      dense
                      outlined
                      :rules="[formRules.required,formRules.pswMatch(user.password2,user.password)]"
                      @click:append="show2 = !show2"
                      @keyup.enter="pswReminder"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      block
                      class="primary darken-1 white--text"
                      :loading="loading"
                      @click="pswReminder"
                    >
                      Reimposta
                    </v-btn>
                    <v-alert
                      v-if="error"
                      dense
                      outlined
                      color="error"
                      class="my-5"
                      dismissible
                    >
                      {{ error }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-row>
                <v-col>
                  <p>Password Reimpostata.</p>
                  <p>La password è stata sostituita, ora è possibile effettuare il login con le nuove credenziali.</p>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="step > 1"
      class="my-4"
    >
      <v-col class="text-center">
        <v-btn
          text
          color="primary"
          :to="{name:'login'}"
        >
          Accedi
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'PasswordReminderSet',
  data: () => ({
    user: {},
    error: null,
    loading: false,
    step: 1,
    pswRulesBox: false,
    errorPasswordMatch: false,
    password_length: 0,
    contains_eight_characters: false,
    contains_number: false,
    contains_uppercase: false,
    contains_special_character: false,
    valid_password: false,
  }),
  computed: {
    ...mapGetters(['formRules']),
  },
  methods: {
    showPswRules() {
      this.pswRulesBox = true
    },
    checkPassword() {
      this.password_length = this.user.password.length
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/

      this.contains_eight_characters = this.password_length > 8

      this.contains_number = /\d/.test(this.user.password)
      this.contains_uppercase = /[A-Z]/.test(this.user.password)
      this.contains_special_character = format.test(this.user.password)

      this.valid_password = this.contains_eight_characters === true
                && this.contains_special_character === true
                && this.contains_uppercase === true
                && this.contains_number === true
    },
    async pswReminder() {
      this.loading = true
      this.error = null

      if (this.$refs.form.validate()) {
        const resp = await this.$api.passwordReminderSet(this.user, this.$route.params.ps)
        if (resp.data.status === 'success') {
          this.step = 2
        } else {
          this.error = resp.data.message
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped>
.is_valid { color: #8BC34A; }
.is_valid:before { width: 100%; }
.no-uppercase {
    text-transform: unset !important;
}
</style>
