import { render, staticRenderFns } from "./password-reminder-set.vue?vue&type=template&id=05411cba&scoped=true&"
import script from "./password-reminder-set.vue?vue&type=script&lang=js&"
export * from "./password-reminder-set.vue?vue&type=script&lang=js&"
import style0 from "./password-reminder-set.vue?vue&type=style&index=0&id=05411cba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05411cba",
  null
  
)

export default component.exports